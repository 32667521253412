import React, { useEffect, useState } from "react"
import styles from "./styles.module.css"

export default function CookieBar() {
    const [ show, setShow]  = useState(true); 

    useEffect(() => {
        let showCookieBar = document.cookie.replace(/(?:(?:^|.*;\s*)_vy_cookiealert\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if(showCookieBar == 'false') {
            setShow(false);
        }
    }, [])

    const _onClose = () => {
        var cookieExp = new Date();
            cookieExp.setDate(cookieExp.getDate() + 365);

        let cookie = `_vy_cookiealert=false; path=/; domain=; expires=${cookieExp.toUTCString()};`;
        document.cookie = cookie;
        setShow(false);
    }

    if(!show) return <></>;

    return(
        <div className={styles.cookiebar}>
            <p className={styles.alert}>WE USE COOKIES TO IMPROVE YOUR BROWSING EXPERIENCE</p>
            <p>And you'll also find them in our meal plans from time to time. Find out more about our cookie policy <a href="/privacy">here</a></p>
            <button className={styles.closeBtn} onClick={_onClose}></button>
        </div>
    )
}